import { MessageStatus } from "./message";
import { SDMessage } from "./sdmessage";

export class Communicator {
    constructor(
        private targetOrigin: string,
        private target: () => IEndpoint,
        private source: () => IEndpoint,
        private defaultTimeout: number = 10000
    ) {}
    private parseJSON(data) {
        try {
            return JSON.parse(data);
        } catch (error) {
            return undefined;
        }
    }
    private unwrapMessage(eventData: any): SDMessage {
        return this.parseJSON(eventData) as SDMessage;
    }

    private wrapMessage(message: SDMessage) {
        return JSON.stringify(message);
    }

    public expose(object: any, action: string): () => void {
        const handler = (args: any) => {
            return new Promise((resolve, reject) => {
                try {
                    resolve(object[action](...args));
                } catch (error) {
                    reject(error);
                }
            });
        };
        const messageHandler = (event: MessageEvent) => {
            if (event.source !== this.target()) {
                return;
            }

            const message: SDMessage = this.unwrapMessage(event.data);
            if (message) {
                const args = message.params;
                if (action === message.action) {
                    handler(args)
                        .then((payload) => {
                            return new SDMessage(action, undefined, undefined, undefined, MessageStatus.OK, payload);
                        })
                        .catch((e) => {
                            console.error("Remote method invocation failed.", e);
                            return new SDMessage(
                                action,
                                undefined,
                                undefined,
                                e instanceof Error ? e.message || "ERROR" : e,
                                MessageStatus.ERROR_UNKNOWN,
                                undefined
                            );
                        })
                        .then((result) => {
                            result.tag = message.tag;
                            result.source = message.source;
                            result.target = message.target;
                            this.target().postMessage(this.wrapMessage(result), this.targetOrigin);
                        });
                }
            }
        };

        this.source().addEventListener("message", messageHandler, false);
        return () => this.source().removeEventListener("message", messageHandler);
    }

    public invoke<T>(method: string, ...args: any[]): Promise<T> {
        let timeout = null;
        const timeoutPromise: Promise<T> = new Promise<T>((resolve, reject) => {
            timeout = setTimeout(() => {
                reject(new Error("Remote method invocation failed: Timeout"));
            }, this.defaultTimeout);
        });
        const relaxed = this.invokeRelaxed(method, ...args);
        relaxed.then(
            () => clearTimeout(timeout),
            () => clearTimeout(timeout)
        );
        return Promise.race([timeoutPromise, relaxed]) as Promise<T>;
    }

    public invokeRelaxed<T>(method: string, ...args: any[]): Promise<T> {
        const result = new Promise<T>((resolve, reject) => {
            const request = this.post(method, ...args);
            const handler = (event: MessageEvent) => {
                if (event.source !== this.target()) {
                    return;
                }
                const response: SDMessage = this.unwrapMessage(event.data);
                if (response) {
                    if (request.tag !== response.tag) {
                        return;
                    }
                    this.source().removeEventListener("message", handler);

                    if (response.status !== MessageStatus.OK || response.error) {
                        reject(new Error(response.error || response.payload));
                    } else {
                        resolve(response.payload);
                    }
                }
            };
            this.source().addEventListener("message", handler, false);
        });
        return result;
    }

    public post(method: string, ...args: any[]) {
        const request = new SDMessage(method, args, undefined, undefined, undefined, undefined);
        this.target().postMessage(this.wrapMessage(request), this.targetOrigin);
        return request;
    }
}

export interface IEndpoint {
    postMessage(message: any, targetOrigin: string, transfer?: any[]): void;
    addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: {}): void;
    removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: {}): void;
}
