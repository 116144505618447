import { Communicator } from "./communicator";

/**
 * The back navigation mode determines how the navigation stack is threated.
 *
 * NONE
 * The host can navigate back without navigating back inside
 * the web app. This is the default.
 *
 * LINEAR
 * The visited urls are stored in a stack, upon back navigation in the host,
 * the web app url is restored to the previous value.
 *
 * COMPRESSED
 * Similar to the LINEAR mode, with the exception that if a url is visited
 * again, the navigation history of the web app is dropped until that point.
 */
export type BackNavigationMode = "NONE" | "LINEAR" | "COMPRESSED";

/**
 * Additional parameters for the navigation.
 * @export
 * @interface NavigationOptions
 */
export interface INavigationOptions {
    /**
     * Whether the new page(s) openned on a new tab or not
     * @type {boolean}
     * @memberof NavigationOptions
     */
    newTab: boolean;
}

export interface INavigationSelection {
    gguids: String[];
    selectedGuid: String;
}

export interface ISingleSelection {
    objectType: String;
    gguid: String;
}

/**
 * Base class of all the navigation builder subclass.
 * @abstract
 * @export
 * @class AbstractNavigationBuilder
 */
export abstract class AbstractNavigationBuilder {
    private _navigation: Promise<void>;

    constructor(protected _communicator: Communicator) {}

    protected abstract invokeNavigation(): Promise<void>;

    /**
     * Trigger the navigation with the configured parameters.
     * @returns {Promise<void>}
     * @memberof AbstractNavigationBuilder
     */
    public navigate(): Promise<void> {
        if (this._navigation) {
            return this._navigation;
        }

        this._navigation = this.invokeNavigation();
        return this._navigation;
    }

    public then<TResult1 = void, TResult2 = never>(
        onfulfilled?: ((value: void) => TResult1 | PromiseLike<TResult1>) | undefined | null,
        onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null
    ): Promise<TResult1 | TResult2> {
        return this.navigate().then(onfulfilled, onrejected);
    }
    public catch<TResult = never>(
        onrejected?: (reason: any) => TResult | PromiseLike<TResult>
    ): Promise<void | TResult> {
        return this.navigate().then(null, onrejected);
    }
}

/**
 * Navigation builder for navigating without context.
 * @export
 * @class NavigationBuilder
 * @extends {AbstractNavigationBuilder}
 */
export class NavigationBuilder extends AbstractNavigationBuilder {
    protected _intentName: String;
    protected _newTab: boolean = false;

    /**
     * Set the navigation intent.
     * @param {String} intentName
     * @returns {this}
     * @memberof NavigationBuilder
     */
    public withIntent(intentName: String): this {
        this._intentName = intentName;
        return this;
    }
    /**
     * Whether the new page(s) openned on a new tab or not
     * @param {boolean} [newTab=true]
     * @returns {this}
     * @memberof NavigationBuilder
     */
    public openOnNewTab(newTab: boolean = true): this {
        this._newTab = newTab;
        return this;
    }

    protected invokeNavigation(): Promise<void> {
        const options: INavigationOptions = {
            newTab: this._newTab,
        };
        return this._communicator.invoke("navigate", this._intentName, options);
    }
}

/**
 * Navigation builder for navigating with data obejct.
 * @export
 * @class NavigationWithRecordBuilder
 * @extends {NavigationBuilder}
 */
export class NavigationWithRecordBuilder extends NavigationBuilder {
    protected _objectType: String;
    protected _gguid: String;

    /**
     * Sets the type of the data object.
     * @param {String} objectType
     * @returns {this}
     * @memberof NavigationWithRecordBuilder
     */
    public withObjectType(objectType: String): this {
        this._objectType = objectType;
        return this;
    }

    /**
     * Sets the id (gguid) of the data object
     * @param {String} gguid
     * @returns {this}
     * @memberof NavigationWithRecordBuilder
     */
    public withGguid(gguid: String): this {
        this._gguid = gguid;
        return this;
    }

    protected invokeNavigation(): Promise<void> {
        const options: INavigationOptions = {
            newTab: this._newTab,
        };
        return this._communicator.invoke(
            "navigateWithRecord",
            this._objectType,
            this._gguid,
            this._intentName,
            options
        );
    }
}

/**
 * Navigation builder for navigating with a list of data obejcts.
 * @export
 * @class NavigationWithRecordsBuilder
 * @extends {NavigationBuilder}
 */
export class NavigationWithRecordsBuilder extends NavigationBuilder {
    protected _objectType: String;
    protected _selectedGguid: String;
    protected _gguids: String[];

    /**
     * Sets the type of the data objects.
     * @param {String} objectType
     * @returns {this}
     * @memberof NavigationWithRecordsBuilder
     */
    public withObjectType(objectType: String): this {
        this._objectType = objectType;
        return this;
    }
    /**
     * Sets the id (gguid) of the selected data object. Selected id should be
     * presented in the gguids list, see {@link withGguid}.
     * @param {String} selectedGguid
     * @returns {this}
     * @memberof NavigationWithRecordsBuilder
     */
    public withSelectedGguid(selectedGguid: String): this {
        this._selectedGguid = selectedGguid;
        return this;
    }
    /**
     * Sets the list of the data objects ids.
     * @param {string[]} gguids
     * @returns {this}
     * @memberof NavigationWithRecordsBuilder
     */
    public withGguids(gguids: string[]): this {
        this._gguids = gguids;
        return this;
    }

    protected invokeNavigation(): Promise<void> {
        const options: INavigationOptions = {
            newTab: this._newTab,
        };
        const selection: INavigationSelection = {
            selectedGuid: this._selectedGguid ? this._selectedGguid : null,
            gguids: this._gguids,
        };
        return this._communicator.invoke("navigateWithRecords", this._objectType, selection, this._intentName, options);
    }
}

/**
 * Navigation builder for dossier navigation
 * @export
 * @class NavigationWithDossierBuilder
 * @extends {NavigationBuilder}
 */
export class NavigationWithDossierBuilder extends NavigationBuilder {
    protected _sourceObjectType: String;
    protected _sourceGguid: String;
    protected _selectedObjectType: String;
    protected _selectedGguid: String;
    protected _typesToShow: String[];

    /**
     * Sets of the object type of the source item.
     * @param {String} sourceObjectType
     * @returns {this}
     * @memberof NavigationWithDossierBuilder
     */
    public withSourceObjectType(sourceObjectType: String): this {
        this._sourceObjectType = sourceObjectType;
        return this;
    }
    /**
     * Sets the gguid of the source item.
     * @param {String} sourceGguid
     * @returns {this}
     * @memberof NavigationWithDossierBuilder
     */
    public withSourceGguid(sourceGguid: String): this {
        this._sourceGguid = sourceGguid;
        return this;
    }
    /**
     * Sets of the object type of the selected item.
     * @param {String} selectedObjectType
     * @returns {this}
     * @memberof NavigationWithDossierBuilder
     */
    public withSelectedObjectType(selectedObjectType: String): this {
        this._selectedObjectType = selectedObjectType;
        return this;
    }
    /**
     * Sets the selected data object guid, that should be member of the dossier items
     * @param {String} selectedGguid
     * @returns {this}
     * @memberof NavigationWithDossierBuilder
     */
    public withSelectedGguid(selectedGguid: String): this {
        this._selectedGguid = selectedGguid;
        return this;
    }
    /**
     * Sets the typesToShow list, which defines the available object types.
     * If it is empty the server configuration is used.
     * @param {String[]} typesToShow
     * @returns {this}
     * @memberof NavigationWithDossierBuilder
     */
    public withTypeToShow(typesToShow: String[]): this {
        this._typesToShow = typesToShow;
        return this;
    }

    protected invokeNavigation(): Promise<void> {
        const options: INavigationOptions = {
            newTab: this._newTab,
        };
        const source: ISingleSelection = {
            gguid: this._sourceGguid,
            objectType: this._sourceObjectType,
        };
        let selection: ISingleSelection;
        if (this._selectedGguid && this._selectedObjectType) {
            selection = {
                gguid: this._selectedGguid,
                objectType: this._selectedObjectType,
            };
        }
        return this._communicator.invoke(
            "navigateToDossier",
            source,
            selection,
            this._intentName,
            this._typesToShow,
            options
        );
    }
}
