/* eslint-disable camelcase */
export const DialogButtonId_OK = "OK";
export const DialogButtonId_YES = "YES";
export const DialogButtonId_NO = "NO";
export const DialogButtonId_CANCEL = "CANCEL";

export const DialogButtonPreset_OK = "OK";
export const DialogButtonPreset_OK_CANCEL = "OK_CANCEL";
export const DialogButtonPreset_YES_NO = "YES_NO";
export const DialogButtonPreset_YES_NO_CANCEL = "YES_NO_CANCEL";

export const DialogStyle_INFO = "INFO";
export const DialogStyle_WARNING = "WARNING";
export const DialogStyle_ERROR = "ERROR";
/* eslint-enable camelcase */

export type DialogButtonId = "OK" | "YES" | "NO" | "CANCEL" | (string & { x?: never });

export type DialogButtonPreset = "OK" | "OK_CANCEL" | "YES_NO" | "YES_NO_CANCEL";

export type DialogStyle = "INFO" | "WARNING" | "ERROR";

export interface IDialogButtonDefinition {
    /**
     * The ID that is used to identify the pressed button when processing the result.
     *
     * Note that it's not displayed to the user (in case the caption is present).
     */
    id: DialogButtonId;
    /**
     * The caption that will be shown to the user on the UI.
     *
     * Note that for default buttons (buttons with ID "OK", "YES", "NO" or "CANCEL") this
     * is automatically set with a translated value if absent.
     */
    caption?: string;
    /**
     * Indicates whether the button defines the primary action and should be visualized differenly.
     */
    primary?: boolean;
}

export interface IDialogOptions {
    /**
     * The title for the dialog window.
     */
    title: string;
    /**
     * The message that will be shown in the main area of the dialog window.
     */
    message: string;
    /**
     * The buttons that will be shown for user interaction.
     *
     * It can be a preset ("OK" | "OK_CANCEL" | "YES_NO" | "YES_NO_CANCEL") or a list of
     * DialogButtonDefinition that allows custom buttons to be shown.
     */
    buttons?: DialogButtonPreset | IDialogButtonDefinition[];
    /**
     * Defines the style of the dialog that usually shows the user how severe the shown information is.
     */
    style?: DialogStyle;
}

export interface IDialogResult {
    /**
     * The ID of the button that was pressed.
     */
    buttonId: DialogButtonId;

    /**
     * Returns true if OK or YES was pressed
     */
    wasConfirmed(): boolean;
}
