export const getMethods = (obj) => {
    let props: string[] = [];
    const isConstructor = (e) => "constructor" === e;
    const isFunction = (e) => typeof obj[e] === "function";
    const isNormalFunction = (e) => !isConstructor(e) && isFunction(e);

    while ((obj = Object.getPrototypeOf(obj)) && obj !== Object.prototype) {
        const allProps = Object.getOwnPropertyNames(obj);
        const functions = allProps.filter((e) => isNormalFunction(e));
        props = props.concat(functions);
    }

    return props.sort().filter((e, i, arr) => {
        return e !== arr[i + 1];
    });
};
