export const createTunnel = (host: string = "", readyCallback: () => void) => {
    const tunnel = document.createElement("iframe");
    tunnel.tabIndex = -1;
    tunnel.style.width = "0";
    tunnel.style.height = "0";
    tunnel.style.margin = "0";
    tunnel.style.padding = "0";
    tunnel.style.border = "none";
    tunnel.style.position = "absolute";
    tunnel.style.zIndex = "-1";
    tunnel.style.pointerEvents = "none";
    if (host.lastIndexOf("/") === host.length - 1) {
        host = host.substring(0, host.length - 1);
    }
    tunnel.setAttribute("src", host + "/apps/content/api/tunnel.html");
    window.addEventListener("message", (e) => {
        if (e.data === "ready" && readyCallback) {
            readyCallback();
        }
    });
    return tunnel;
};
