import { Communicator, IEndpoint } from "../common/communicator";
import { trackUrlChanges } from "./smartdesign_url_change";
import { createTunnel as createTunnelInternal } from "../common/tunnel";
import { InitParameters } from "../common/initParameters";
import { SearchQuery, OpenSearchResponse } from "../common/query";
import {
    NavigationBuilder,
    NavigationWithRecordBuilder,
    AbstractNavigationBuilder,
    NavigationWithRecordsBuilder,
    NavigationWithDossierBuilder,
    BackNavigationMode,
} from "../common/navigation";

import "whatwg-fetch";
import { IDisposable } from "../common/disposable";
import { ActivationMessage, IActivationChangeHandler } from "../common/activation";
import { getMethods } from "../common/functionUtil";

import { Field, SingleValueField, MultiValueField } from "../common/field";
export * from "../common/field";

import { BasicState } from "../common/basicstate";
export { BasicState } from "../common/basicstate";

import { LocaleInformation } from "../common/localeInformation";
// eslint-disable-next-line camelcase
import { IDialogOptions, DialogButtonPreset_YES_NO, IDialogResult, DialogStyle } from "../common/dialog";
import { getEffectiveOptions, IOpenDialogResponse, DialogResultImpl } from "../common/dialogutils";
import { LoadingIndicatorOptions } from "../common/loadingindicator";

class NavigationImpl implements SmartDesign.INavigation {
    private _supportedNavigationModes: BackNavigationMode[];
    private _intents: string[];
    private _reloadSupported: boolean;
    private _navigationScheduler: (actor) => void = (actor) =>
        setTimeout(() => {
            actor();
        }, 0);

    constructor(initParameters: InitParameters<any>, private communicator: Communicator) {
        this._intents = (initParameters.intents || []).slice();
        this._reloadSupported = initParameters.reloadSupported;
        this._supportedNavigationModes = (initParameters.backNavigationModes || []).slice();
    }
    public get intents(): string[] {
        return this._intents.slice();
    }

    public isBackwardNavigationModeSupported(mode: BackNavigationMode): boolean {
        return mode === "NONE" || this._supportedNavigationModes.indexOf(mode) > -1;
    }

    public setBackwardNavigationMode(mode: BackNavigationMode): Promise<void> {
        if (!this.isBackwardNavigationModeSupported(mode)) {
            return Promise.reject(new Error("Navigation mode is not supported"));
        }
        return this.communicator.invoke("setBackwardNavigationMode", mode);
    }

    public navigate(...args: any[]): NavigationBuilder {
        let intent;
        if (args.length >= 1) {
            intent = args[0];
        }
        const navigationBuilder: NavigationBuilder = new NavigationBuilder(this.communicator).withIntent(intent);
        this.triggerNavigation(navigationBuilder);
        return navigationBuilder;
    }
    public navigateWithRecord(...args: any[]): NavigationWithRecordBuilder {
        let objectType;
        let gguid;
        let intent;
        if (args.length >= 2) {
            objectType = args[0];
            gguid = args[1];
        }
        if (args.length >= 3) {
            intent = args[2];
        }
        const navigationBuilder: NavigationWithRecordBuilder = new NavigationWithRecordBuilder(this.communicator)
            .withObjectType(objectType)
            .withGguid(gguid)
            .withIntent(intent);
        this.triggerNavigation(navigationBuilder);
        return navigationBuilder;
    }
    public navigateWithRecords(...args: any[]): NavigationWithRecordsBuilder {
        let objectType;
        let gguid;
        let gguids;
        let intent;
        if (args.length >= 2) {
            objectType = args[0];
            gguids = args[1];
        }
        if (args.length >= 3) {
            gguid = args[2];
        }
        if (args.length >= 4) {
            intent = args[3];
        }
        const navigationBuilder: NavigationWithRecordsBuilder = new NavigationWithRecordsBuilder(this.communicator)
            .withObjectType(objectType)
            .withGguids(gguids)
            .withSelectedGguid(gguid)
            .withIntent(intent);
        this.triggerNavigation(navigationBuilder);
        return navigationBuilder;
    }
    public navigateToDossier(...args: any[]): NavigationWithDossierBuilder {
        let objectType;
        let gguid;
        let intent;
        if (args.length >= 2) {
            objectType = args[0];
            gguid = args[1];
        }
        if (args.length >= 3) {
            intent = args[2];
        }
        const navigationBuilder: NavigationWithDossierBuilder = new NavigationWithDossierBuilder(this.communicator)
            .withSourceGguid(gguid)
            .withSourceObjectType(objectType)
            .withIntent(intent);
        this.triggerNavigation(navigationBuilder);
        return navigationBuilder;
    }
    public get reloadSupported(): boolean {
        return this._reloadSupported;
    }
    public reload(): Promise<void> {
        if (!this.reloadSupported) {
            return Promise.reject(new Error("Reload is not supported"));
        }
        return this.communicator.invoke("reload");
    }
    public navigateBack(): Promise<void> {
        return this.communicator.invoke("navigateBack");
    }
    public navigateHome(): Promise<void> {
        return this.communicator.invoke("navigateHome");
    }
    public urlChanged(url: string): Promise<void> {
        return this.communicator.invoke("urlChanged", url);
    }
    private triggerNavigation(navigationBuilder: AbstractNavigationBuilder): void {
        this._navigationScheduler(() => navigationBuilder.navigate());
    }
}

class SearchImpl implements SmartDesign.ISearch {
    private _openSearchSupported: boolean;
    private isSearchPending: boolean;

    constructor(private initParameters: InitParameters<any>, private communicator: Communicator) {
        this._openSearchSupported = initParameters.openSearchSupported;
    }

    public get openSearchSupported(): boolean {
        return this._openSearchSupported;
    }

    public openSearch(objectType: string, query?: SearchQuery): Promise<OpenSearchResponse> {
        if (!this.openSearchSupported) {
            return Promise.reject(new Error("OpenSearch api method is not supported"));
        }
        if (this.isSearchPending) {
            return Promise.reject(
                new Error(
                    "The OpenSearch method can not be executed simultaneously, the operation is already in a pending state."
                )
            );
        }

        const openSearch: Promise<OpenSearchResponse> = this.communicator.invokeRelaxed(
            "openSearch",
            objectType,
            query
        );
        this.isSearchPending = true;
        return openSearch
            .then((p) => {
                this.isSearchPending = false;
                return p;
            })
            .catch((e) => {
                this.isSearchPending = false;
                throw e;
            });
    }
}

class DialogApiImpl implements SmartDesign.IDialogApi {
    private _openSupported: boolean;

    constructor(private initParameters: InitParameters<any>, private communicator: Communicator) {
        this._openSupported = initParameters.openDialogSupported;
    }

    public get openSupported(): boolean {
        return this._openSupported;
    }

    public alert(title: string, message: string, style?: DialogStyle): Promise<IDialogResult> {
        return this.open({
            title,
            message,
            style,
        });
    }
    public confirm(title: string, message: string, style?: DialogStyle): Promise<IDialogResult> {
        return this.open({
            title,
            message,
            buttons: DialogButtonPreset_YES_NO,
            style,
        });
    }
    public open(options: IDialogOptions): Promise<IDialogResult> {
        if (!this.openSupported) {
            return Promise.reject(new Error("Dialog API is not supported"));
        }

        const openDialog: Promise<IOpenDialogResponse> = this.communicator.invokeRelaxed(
            "openDialog",
            getEffectiveOptions(options)
        );

        return openDialog.then((response) => new DialogResultImpl(response));
    }
}

type StateChangeCallback<T> = (newState: T, initialStateChange: boolean) => void;

class StateImpl<T extends ICustomState> implements SmartDesign.IState<T> {
    private _updateSupported: boolean;
    private _onChangeSupported: boolean;
    private _current: T & BasicState;
    private _callbacks: Array<StateChangeCallback<T & BasicState>> = [];

    constructor(public initParameters: InitParameters<T>, private communicator: Communicator) {
        this._updateSupported = initParameters.stateUpdateSupported;
        this._onChangeSupported = initParameters.stateOnChangeSupported;
        this._current = (initParameters.state || {}) as T & BasicState;
        this.communicator.expose(
            {
                setState: (state) => this.setState(state),
            },
            "setState"
        );
        /* Promise resolve does not work, as this class is created in a micro-task.
           In order to properly defer the initial state change event, we have to use
           setTimeout, so that the user land code can actually subscribe to it before
           firing the event.*/
        setTimeout(() => {
            this.fireStateChange(true);
        }, 0);
    }

    get onChangeSupported() {
        return this._onChangeSupported;
    }

    get updateSupported() {
        return this._updateSupported;
    }

    public onChange(callback: StateChangeCallback<T & BasicState>): IDisposable {
        this._callbacks.push(callback);
        return {
            dispose() {
                this._callbacks = this._callbacks.filter((p) => p !== callback);
            },
        };
    }

    public update(): Promise<T & BasicState> {
        if (!this._updateSupported) {
            return Promise.reject(new Error("UpdateState api method is not supported"));
        }
        return this.communicator.invoke("updateState", this._current).then(() => this._current);
    }

    get current(): T & BasicState {
        return this._current;
    }

    private setState(state: T & BasicState) {
        this._current = state;
        this.fireStateChange(false);
    }

    private fireStateChange(initial: boolean) {
        if (this.onChangeSupported) {
            this._callbacks.forEach((p) => p(this._current, initial));
        }
    }
}

class ActivationChangeHandlerImpl implements IActivationChangeHandler {
    constructor(private handler: (message: ActivationMessage) => void) {}
    public onActivationChanged(message: ActivationMessage) {
        this.handler(message);
    }
}

class ActivationServiceImpl implements SmartDesign.IActivationService {
    constructor(private communicator: Communicator) {}

    public registerListener(actor: (message: ActivationMessage) => void): IDisposable {
        const api = new ActivationChangeHandlerImpl(actor);
        const disposables = getMethods(api).map((method) => this.communicator.expose(api, method));
        const dispose = () => {
            disposables.forEach((disposable) => disposable());
        };
        return { dispose };
    }
}

class LoadingIndicatorImpl implements SmartDesign.ILoadingIndicator {
    constructor(public initParameters: InitParameters<any>, private communicator: Communicator) {}

    public show(options?: LoadingIndicatorOptions): Promise<void> {
        return this.communicator.invoke("showLoadingIndicator", options);
    }
    public hide(): Promise<void> {
        return this.communicator.invoke("hideLoadingIndicator");
    }
    public showDuring(promise: Promise<any>, options?: LoadingIndicatorOptions): Promise<void> {
        return this.show(options).then(() => promise.catch(() => null).then(() => this.hide()));
    }

    public get showSupported(): boolean {
        return this.initParameters.loadingIndicatorShowSupported;
    }
    public get blockSupported(): boolean {
        return this.initParameters.loadingIndicatorBlockSupported;
    }
    public get blockHostSupported(): boolean {
        return this.initParameters.loadingIndicatorBlockHostSupported;
    }
}

class SmartDesignAPIImpl<T extends ICustomState> implements SmartDesign.IAPI<T> {
    public _restEnabled: boolean;

    constructor(public initParameters: InitParameters<T>, private communicator: Communicator) {
        this._restEnabled = initParameters.restEnabled;
    }

    public get locale() {
        if (this.initParameters.locale != null) {
            return { ...this.initParameters.locale };
        }
        return null;
    }

    public Navigation: SmartDesign.INavigation = new NavigationImpl(this.initParameters, this.communicator);
    public Search: SmartDesign.ISearch = new SearchImpl(this.initParameters, this.communicator);
    public Dialog: SmartDesign.IDialogApi = new DialogApiImpl(this.initParameters, this.communicator);
    public State: SmartDesign.IState<T> = new StateImpl<T>(this.initParameters, this.communicator);
    public ActivationService: SmartDesign.IActivationService = new ActivationServiceImpl(this.communicator);
    public LoadingIndicator: SmartDesign.ILoadingIndicator = new LoadingIndicatorImpl(
        this.initParameters,
        this.communicator
    );

    public get restEnabled(): boolean {
        return this._restEnabled;
    }

    public provideCaption(caption: string): Promise<void> {
        return this.communicator.invoke("provideCaption", caption);
    }

    public fetch(input: string, init?: RequestInit): Promise<Response> {
        if (!this.initParameters.restEnabled) {
            return Promise.reject(new Error("Rest API is disabled"));
        }
        input = this.initParameters.baseUrl + input;
        init = init || {};
        init.mode = "cors";
        init.headers = init.headers || [];

        this.addMissingHeader(init.headers, "SMARTDESIGN-TOKEN", this.initParameters.token);
        this.addMissingHeader(init.headers, "Accept", "application/json");

        if (init.body) {
            this.addMissingHeader(init.headers, "Content-Type", "application/json");
        }

        return fetch(input, init);
    }

    public trackUrlChanges() {
        trackUrlChanges((url) => this.Navigation.urlChanged(url));
    }

    public requestResize(width: number, height: number): Promise<void> {
        return this.communicator.invoke("requestResize", width, height);
    }

    private addMissingHeader<U extends RequestInit["headers"]>(headers: U, key: string, defaultValue: string): void {
        if (headers instanceof Headers) {
            if (!headers.has(key)) {
                headers.append(key, defaultValue);
            }
        } else if (Array.isArray(headers)) {
            if (headers.filter((header) => header[0].toLowerCase() === key.toLowerCase()).length === 0) {
                headers.push([key, defaultValue]);
            }
        } else {
            if (!headers[key]) {
                headers[key] = defaultValue;
            }
        }
    }
}
/**
 * Main entry point for the html app integration of SmartDesign
 * Amongst other things, it provides access to the rest api,
 * can trigger navigations in the surrounding SmartDesign application etc.
 */
export namespace SmartDesign {
    export interface IAPI<T extends ICustomState = any> {
        /**
         * Provides access to the Navigation api.
         */
        Navigation: INavigation;
        /**
         * Provides access to the Dialog api.
         */
        Dialog: IDialogApi;
        /**
         * Provides access to the Search api.
         */
        Search: ISearch;
        /**
         * Provides acess to the activation service.
         */
        ActivationService: IActivationService;
        /**
         * Provides access to the application-wise loading indicator.
         */
        LoadingIndicator: ILoadingIndicator;
        /**
         * Provides access to the State api
         */
        State: IState<T>;

        /**
         * An object containing information about the user locale
         */
        readonly locale?: LocaleInformation;
        /**
         * Sets the caption of the surrounding container e.g. in case of web apps the title of the page.
         */
        provideCaption?: (caption: string) => Promise<void>;
        /**
         * Provides access to the REST API and automatically appends the necessary request headers.
         * Wraps the fetch api, for further information see: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
         * Returns a rejected promise in case when the rest api is disabled.
         */
        fetch(input: string, init?: RequestInit): Promise<Response>;

        /**
         * Enables automatic tracking of url changes in order to save the state of the app properly.
         */
        trackUrlChanges();
        /**
         * Signals a change in the required width and height of the embedded app / widget.
         * The values are interpreted as pixels.
         * In case when the content must adapt to the container size, use any negative number.
         */
        requestResize(width: number, height: number): void;
        /**
         * A flag which indicates whether the rest operations are enabled
         */
        readonly restEnabled: boolean;
    }
    /**
     * Entry point for triggering a navigation in the surrounding SmartDesign application
     */
    export interface INavigation {
        /**
         * Returns a flag which indicates whether the given navigation mode is supported.
         * @param mode BackNavigationMode to be checked
         */
        isBackwardNavigationModeSupported(mode: BackNavigationMode): boolean;
        /**
         * Sets the backward navigation mode which determines how the navigation stack is treated.
         * Returns a rejected Promise if the navigation mode is not supported.
         * @param mode BackNavigationMode to apply
         */
        setBackwardNavigationMode(mode: BackNavigationMode): Promise<void>;

        /**
         * Triggers a navigation with the given navigation intent without any context information.
         * Additional navigation parameters can be configured by using the returned NavigationBuilder.
         * @see {@link NavigationBuilder}
         * @param {string} intent
         * @returns {NavigationBuilder}
         * @memberof Navigation
         */
        navigate(intent?: string): NavigationBuilder;
        /**
         * Triggers a navigation to the given dataobject set by returned navigation builder.
         * @see {@link NavigationWithRecordBuilder}
         * @returns {NavigationWithRecordBuilder}
         * @memberof Navigation
         */
        navigateWithRecord(): NavigationWithRecordBuilder;
        /**
         * Triggers a navigation to the given dataobject, by default it uses the
         * 'open record with list' navigation intent. Additional navigation parameters
         * can be configured by using the returned NavigationWithRecordBuilder.
         * @see {@link NavigationWithRecordBuilder}
         * @param {string} objectType
         * @param {String} gguid
         * @param {string} [intent]
         * @returns {NavigationWithRecordBuilder}
         * @memberof Navigation
         */
        navigateWithRecord(objectType: string, gguid: String, intent?: string): NavigationWithRecordBuilder;
        /**
         * Triggers a navigation with the selected data objects. Additional navigation parameters
         * can be configured by using the returned NavigationWithRecordsBuilder.
         * @see {@link NavigationWithRecordsBuilder}
         * @returns {NavigationWithRecordsBuilder}
         * @memberof Navigation
         */
        navigateWithRecords(): NavigationWithRecordsBuilder;

        /**
         * Triggers a navigation with the selected data objects. Additional navigation parameters
         * can be configured by using the returned NavigationWithRecordsBuilder.
         * @see {@link NavigationWithRecordsBuilder}
         * @param {string} objectType type of the data objects
         * @param {string[]} gguids array of the data objects ids
         * @param {string} [selectedGguid] selected data object id, if there is any
         * @param {string} [intent] navigation intent
         * @returns {NavigationWithRecordsBuilder}
         * @memberof Navigation
         */
        navigateWithRecords(
            objectType: string,
            gguids: string[],
            selectedGguid?: string,
            intent?: string
        ): NavigationWithRecordsBuilder;
        /**
         * Triggers a navigation with dossier items of the source object. Additional navigation
         * parameters can be configured by using the returned NavigationWithDossierBuilder.
         * @see {@link NavigationWithDossierBuilder}
         * @returns {NavigationWithDossierBuilder}
         * @memberof Navigation
         */
        navigateToDossier(): NavigationWithDossierBuilder;
        /**
         * Triggers a navigation with dossier items of the source object. Additional navigation
         * parameters can be configured by using the returned NavigationWithDossierBuilder.
         * @see {@link NavigationWithDossierBuilder}
         * @param {string} sourceObjectType type of the source data object
         * @param {string} sourceGguid id of the source data object
         * @param {string} [intent] Navigation intent
         * @returns {NavigationWithDossierBuilder}
         * @memberof Navigation
         */
        navigateToDossier(sourceObjectType: string, sourceGguid: string, intent?: string): NavigationWithDossierBuilder;
        /**
         * Triggers a back navigation
         */
        navigateBack(): void;
        /**
         * Triggers a home navigation
         */
        navigateHome(): void;
        /**
         * A flag which indicates whether the api method [[reload]] is supported
         */
        reloadSupported?: boolean;
        /**
         * Reloads the current page(s) in the host application
         */
        reload(): void;
        /**
         * Signals a change in the state of the embedded app.
         * This url is going to be used when restoring the app's state.
         */
        urlChanged(url: string): void;
        /**
         * List of possible navigation intents
         */
        readonly intents: string[];
    }

    /**
     * Entry point for triggering search functionalities
     */
    export interface ISearch {
        /**
         * A flag which indicates whether the api method [[openSearch]] is supported
         */
        readonly openSearchSupported: boolean;
        /**
         * Opens a native search dialog and returns with the selected record's gguid.
         * In case when the user cancels the operation, null value is returned.
         * Returns a rejected promise in case when the api method is not supported.
         */
        openSearch(objectType: string, query?: SearchQuery): Promise<OpenSearchResponse>;
    }

    export interface IDialogApi {
        /**
         * A flag which indicates whether opening a dialog is supported
         */
        readonly openSupported: boolean;
        /**
         * Opens a simple alert dialog with the given title and message.
         */
        alert(title: string, message: string, style?: DialogStyle): Promise<IDialogResult>;

        /**
         * Opens a simple confirmation dialog with the given title, message, a "Yes" and a "No" button.
         *
         * If "Yes" was pressed, the result's wasConfirmed() method returns true.
         */
        confirm(title: string, message: string, style?: DialogStyle): Promise<IDialogResult>;

        /**
         * Opens a dialog with the options given in the first argument.
         */
        open(options: IDialogOptions): Promise<IDialogResult>;
    }

    /**
     * Entry point for accessing context items
     */
    export interface IState<T extends ICustomState> {
        /**
         * A flag which indicates whether the state change part of the api is supported.
         */
        readonly onChangeSupported: boolean;
        /**
         * Provides a hook for initial and subsequent state change handling.
         */
        onChange(callback: (newState: T & BasicState, initialStateChange: boolean) => void);

        /**
         * A flag which indicates whether the state update part of the api is supported.
         */
        readonly updateSupported: boolean;
        /**
         * Updates the state on the host side based on the changes made in the client.
         * This must be invoked after e.g. a field value has been changed.
         */
        update(): Promise<T & BasicState>;

        /**
         * Provides access to the latest state instance.
         */
        readonly current: T & BasicState;
    }

    /**
     * Entry point for registering listener to the activation event.
     */
    export interface IActivationService {
        /**
         * Register a listener which is notified, when the enable state of
         * the web app widget is changed.
         * @param {(ActivationMessage) => void} actor
         * @returns {IDisposable}
         * @memberof ActivationService
         */
        registerListener(actor: (message: ActivationMessage) => void): IDisposable;
    }

    /**
     * Entry point for accessing the main application-wise loading indicator
     */
    export interface ILoadingIndicator {
        /**
         * Shows the main application-wise loading indicator.
         *
         * @param options Defines options that can modify behaviors like UI blocking or timeout.
         */
        show(options?: LoadingIndicatorOptions);
        /**
         * Hides the main application-wise loading indicator.
         */
        hide();
        /**
         * Shows the main application-wise loading indicator until the given promise is pending.
         *
         * @param promise The promise that controls the loading indicator state.
         */
        showDuring(promise: Promise<any>, options?: LoadingIndicatorOptions);
        /**
         * A flag which indicates whether showing the loading indicator is supported.
         */
        readonly showSupported: boolean;
        /**
         * A flag which indicates whether user interaction blocking on the current app/widget is supported.
         */
        readonly blockSupported: boolean;
        /**
         * A flag which indicates whether user interaction blocking on the host is supported.
         */
        readonly blockHostSupported: boolean;
    }
}

export interface IPromise<T> extends Promise<T> {}

/**
 * Connects to the SmartDesign api.
 * @param targetOrigin The origin of the SmartDesign host, for hosted apps this can be omitted
 * @param target Accessor method to the window object of the SmartDesign host, usually ()=> window.parent
 * @param source Accessor method to the window object of the SmartDesign app frame, usually ()=> window
 * @param timeout Timeout for the sent messages
 */
export const connect = <T extends ICustomState>(
    targetOrigin: string = window.location.origin,
    target?: () => IEndpoint,
    source?: () => IEndpoint,
    timeout: number = 10000
): IPromise<SmartDesign.IAPI<T>> => {
    const delayedConnect = new Promise<SmartDesign.IAPI<T>>((resolve) => {
        setTimeout(() => {
            if ((window as any).SD_DIRECT) {
                (window as any).SD_DIRECT.initApp();
                const loop = () => {
                    if ((window as any).SD_DIRECT_API) {
                        resolve((window as any).SD_DIRECT_API);
                    } else {
                        requestAnimationFrame(loop);
                    }
                };
                loop();
            } else {
                resolve((window as any).SD_DIRECT_API);
            }
        }, 0);
    }).then((directApi) => {
        if (directApi) {
            return Promise.resolve(directApi as SmartDesign.IAPI<T>);
        } else {
            const waitForLoad = new Promise((resolve) => {
                if (document.readyState === "complete") {
                    resolve();
                } else {
                    window.addEventListener("load", resolve);
                }
            });
            const sourceProvider = source ? source : () => window;
            const communicator = new Communicator(
                targetOrigin,
                target ? target : () => window.parent,
                sourceProvider,
                timeout
            );
            const sourceWindow = sourceProvider() as Window;
            const origin = sourceWindow.location ? sourceWindow.location.origin : undefined;
            return waitForLoad
                .then(() => communicator.invoke("initApp", origin))
                .then((initParameters: InitParameters<T>) => {
                    return new SmartDesignAPIImpl<T>(initParameters, communicator);
                });
        }
    });

    return delayedConnect;
};

/**
 * Creates a tunnel for cross tab communication.
 * The resulting iframe should be appended to the html document, and then it can be used as a parameter to [[connect]].
 * @param host Url of the SmartDesign instance. e.g. https://mycompany.com/smartdesign or http://login.smartwe.de/SmartWe/
 * @param readyCallback Callback to execute after the tunnel is established
 */
// eslint-disable-next-line valid-jsdoc
export const createTunnel = (host: string = "", readyCallback: () => void): HTMLIFrameElement => {
    return createTunnelInternal(host, readyCallback);
};

export interface ICustomState {
    [name: string]: Field<any> | boolean | number | string;
}

export const isSingleValueField = <T>(field: Field<T>): field is SingleValueField<T> => {
    return !Array.isArray(field.value);
};

export const isMultiValueField = <T>(field: Field<T>): field is MultiValueField<T> => {
    return Array.isArray(field.value);
};
