/* istanbul ignore file */

/**
 * Base interface defining messaging contract.
 *
 * TODO: create contract validator
 */
export interface IMessageBase {
    /**
     * Auto-generated identifier used for message identification and as a correlation ID for request-response pairs.
     */
    tag: string;

    /**
     * Name of message subject (typically pub/sub topic or RPC method name)
     */
    action: string;

    /**
     * Optional parameter section, is typically populated in RPCs requests/responses.
     */
    params?: { [paramName: string]: any };

    /**
     * Payload (if any) of the message.
     */
    payload?: any;

    /**
     * Message metadata.
     */
    meta: IMeta;
    error?: any;
    status?: MessageStatus;
}

export interface IMeta {
    /**
     * DEPRECATED.
     * Set SDX message tiers. Will be replaced by message routing in v3.
     */
    tier: Tier;

    /**
     * Float version number for API contract versioning:
     * - 1.0: SDX Messaging v1 (up to SDX Native Frames)
     * - 2.0: SDX Messaging v2 (up to SDX versions <= 3.x.x)
     * - 3.0: CAS Integration Library (from SDX v3, SDX SDK v3)
     */
    apiVersion: number;

    /**
     * Timestamp of message generation (UNIX-time with millis).
     */
    timestamp: number;

    /**
     * Emitter can classify message as a cacheable message for subscribers.
     */
    cache?: boolean;

    /**
     * Indicates a message is coming from a cache or is about a cached resource.
     */
    cached?: boolean;

    /**
     * A message with supplied patterns should invalidate other messages with matching action names.
     * Array of string arrays defining patterns for action names joined by '*'
     */
    invalidates?: string[][];

    /**
     * WebSocket connection ID used by SDX Server.
     */
    connectionId?: string;

    /**
     * Timeout in milliseconds
     */
    timeout?: number;

    [key: string]: any;
}

/**
 * DEPRECATED: set SDX message tiers. Will be replaced by message routing in v3.
 */
export enum Tier {
    INTERAPP,
    CONTAINER,
    BROADCAST,
}

/**
 * Status values in MessageBase
 */
export enum MessageStatus {
    /**
     * No errors, code of success.
     */
    OK = 0,

    /**
     * Message targeted a party that was not ready, or action against an uninitialized party.
     */
    NOT_INITIALIZED = 1,

    /**
     * Authorization error.
     */
    NOT_AUTHORIZED = 2,

    /**
     * Action got invalidated (eg. object instance from cache, session, etc.)
     */
    INVALIDATED = 3,

    /**
     * Message targeted a party that is not available (eg. API proxy response for unavailable backend)
     */
    ERROR_NOT_AVAILABLE = 248,

    /**
     * Messaging (integration) host error
     */
    ERROR_HOST = 249,

    /**
     * No response from any party for the same message tag in given timeout (see Meta).
     */
    ERROR_TIMEOUT = 250,

    /**
     * Message is not applicable with given parameters. Typically used in RPC responses.
     */
    ERROR_INVALID_PARAMS = 251,

    /**
     * Message targeting RPC method does not exist. Typically used in RPC responses.
     */
    ERROR_NO_SUCH_METHOD = 252,

    /**
     * Internal error in service behind given action name.
     */
    ERROR_SERVICE = 253,

    /**
     * Message format and/or payload parse error.
     */
    ERROR_PARSE = 254,

    /**
     * Unknown error occurred during message exchange. See error property for details, if any.
     */
    ERROR_UNKNOWN = 255,
}

export abstract class Message implements IMessageBase {
    public tag: string;

    constructor(
        public action: string,
        public params: any = {},
        public meta: IMeta,
        public error?: string,
        public status?: number,
        public payload: any = {}
    ) {
        this.tag = this.UUID();

        this.meta = {
            apiVersion: 2.0,
            tier: Tier.INTERAPP,
            timestamp: new Date().getTime(),
        };
    }

    public setTier(tier: Tier) {
        if (tier) {
            this.meta.tier = tier;
        }
    }

    private UUID(): string {
        if (typeof window.crypto !== "undefined" && typeof window.crypto.getRandomValues !== "undefined") {
            // If we have a cryptographically secure PRNG, use that
            // http://stackoverflow.com/questions/6906916/collisions-when-generating-uuids-in-javascript
            const buf = new Uint16Array(8);
            window.crypto.getRandomValues(buf);
            const S4 = (num: any) => {
                let ret = num.toString(16);
                while (ret.length < 4) {
                    ret = "0" + ret;
                }
                return ret;
            };
            return (
                S4(buf[0]) +
                S4(buf[1]) +
                "-" +
                S4(buf[2]) +
                "-" +
                S4(buf[3]) +
                "-" +
                S4(buf[4]) +
                "-" +
                S4(buf[5]) +
                S4(buf[6]) +
                S4(buf[7])
            );
        } else {
            const s4 = () => {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            };
            return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
        }
    }
}
