/* eslint-disable camelcase */
import {
    IDialogButtonDefinition,
    DialogButtonId,
    DialogButtonId_CANCEL,
    DialogButtonId_NO,
    DialogButtonId_OK,
    DialogButtonId_YES,
    DialogButtonPreset_OK,
    DialogButtonPreset_OK_CANCEL,
    DialogButtonPreset_YES_NO,
    DialogButtonPreset_YES_NO_CANCEL,
    IDialogOptions,
    IDialogResult,
    DialogStyle_INFO,
} from "./dialog";

const defaultOptions: IDialogOptions = {
    title: null,
    message: null,
    buttons: DialogButtonPreset_OK,
    style: DialogStyle_INFO,
};

export interface IOpenDialogResponse {
    /**
     * The ID of the button that was pressed.
     */
    buttonId: DialogButtonId;
}

export class DialogResultImpl implements IDialogResult {
    public buttonId: DialogButtonId;

    constructor(response: IOpenDialogResponse) {
        this.buttonId = response.buttonId;
    }

    /**
     * Returns true if OK or YES was pressed
     */
    public wasConfirmed(): boolean {
        return this.buttonId === DialogButtonId_OK || this.buttonId === DialogButtonId_YES;
    }
}

export const getEffectiveOptions = (options: IDialogOptions): IDialogOptions => {
    const effectiveOptions: IDialogOptions = { ...defaultOptions };
    Object.keys(options || {})
        .filter((key) => options[key] !== undefined)
        .forEach((key) => (effectiveOptions[key] = options[key]));

    if (!(effectiveOptions.buttons instanceof Array)) {
        const buttonPreset = effectiveOptions.buttons || DialogButtonPreset_OK;
        const effectiveButtons: IDialogButtonDefinition[] = [];

        if (buttonPreset === DialogButtonPreset_OK || buttonPreset === DialogButtonPreset_OK_CANCEL) {
            effectiveButtons.push({
                id: DialogButtonId_OK,
                caption: null,
                primary: buttonPreset === DialogButtonPreset_OK,
            });
        }

        if (buttonPreset === DialogButtonPreset_YES_NO || buttonPreset === DialogButtonPreset_YES_NO_CANCEL) {
            effectiveButtons.push({ id: DialogButtonId_YES, caption: null });
            effectiveButtons.push({
                id: DialogButtonId_NO,
                caption: null,
                primary: buttonPreset === DialogButtonPreset_YES_NO,
            });
        }

        if (buttonPreset === DialogButtonPreset_YES_NO_CANCEL || buttonPreset === DialogButtonPreset_OK_CANCEL) {
            effectiveButtons.push({ id: DialogButtonId_CANCEL, caption: null, primary: true });
        }

        effectiveOptions.buttons = effectiveButtons;
    }

    effectiveOptions.buttons.forEach((button) => {
        button.caption = button.caption || null;
        button.id = button.id || null;
        button.primary = button.primary || false;
    });

    return effectiveOptions;
};
